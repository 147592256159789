import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'

import { DialogContent, DialogTitle, Divider, TextField } from '@mui/material'
import { SUPPORTED_FILE_MIME_TYPES } from 'api/src/common/enums'

import { toast } from '@redwoodjs/web/toast'

import useUploadStorageObject from 'src/lib/hooks/StorageObject/useUploadStorageObject'
import { validatePhone } from 'src/lib/phoneValidator'
import { useAuth } from 'web/src/Providers'

import RichTextEditor from '../Library/RichTextEditor/RichTextEditor'
import UserAvatar from '../UserAvatar/UserAvatar'

const EditUserProfileForm = ({
  personalInfoData,
  user,
  handleFormSubmit,
  setTextEditorAbout,
  setTextEditorLikes,
  setTextEditorDislikes,
  setTextEditorHobbies,
  setAvatarStorageObject,
  name,
  setName,
  phone,
  setPhone,
}) => {
  const { currentUser } = useAuth()

  const clientId = useMemo(() => currentUser.parentData.id, [currentUser])

  const [avatarUrl, setAvatarUrl] = useState(user.avatarUrl)

  const [uploadAvatar, { result: avatarResult, error: avatarError }]: any =
    useUploadStorageObject({
      prefixes: [`${clientId}`, 'user', 'profile'],
      fileName: 'avatar',
      allowedFileMimeTypes: [SUPPORTED_FILE_MIME_TYPES.IMAGE],
    })

  useEffect(() => {
    if (avatarResult) {
      setAvatarStorageObject(avatarResult)
      setAvatarUrl(avatarResult.downloadUrl)
    }
  }, [avatarResult])

  useEffect(() => {
    avatarError && toast.error(avatarError.message)
  }, [avatarError])

  const uploadAvatarHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (!event.target.files || event.target.files.length === 0) {
        throw new Error('You must select an image to upload.')
      }

      const file: File = event.target.files[0]
      uploadAvatar({ file })
    },
    [uploadAvatar],
  )

  const [phoneStatus, setPhoneStatus] = useState(validatePhone(user.phone))

  const setPhoneCheck = (phone: string) => {
    setPhoneStatus(validatePhone(phone))
    setPhone(phone)
  }

  const [aboutMe] = useState(personalInfoData?.aboutMe)
  const [likes] = useState(personalInfoData?.likes)
  const [dislikes] = useState(personalInfoData?.dislikes)
  const [hobbies] = useState(personalInfoData?.hobbies)

  return (
    <form
      className="flex flex-col grow flex-1 h-100"
      onSubmit={(e) => {
        handleFormSubmit(e)
      }}
    >
      <DialogTitle>Edit Profile</DialogTitle>
      <Divider />
      <DialogContent>
        <div className="lg:flex gap-2 items-center mt-4">
          <div className="w-[25%] text-gray-900 text-sm font-medium mb-5 lg:mb-0">
            <UserAvatar src={avatarUrl} height={24} />
          </div>
          <input
            type="file"
            accept="image/*, video/*"
            onChange={(e) => uploadAvatarHandler(e)}
            className="bg-white py-2 px-3 w-full border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          />
        </div>

        <div className="lg:flex gap-2 mt-4 items-center">
          <p className="w-[25%] text-gray-900 text-sm font-medium">Name</p>
          <TextField
            className="bg-white w-full"
            id="userNameEdit"
            variant="outlined"
            size="small"
            sx={{ width: 400 }}
            required={true}
            defaultValue={name || user?.name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="lg:flex gap-2 mt-4 items-center">
          <p className="w-[25%] text-gray-900 text-sm font-medium">
            Phone{' '}
            <span style={phoneStatus?.isValid ? {} : { color: 'orange' }}>
              {phoneStatus?.message}
            </span>
          </p>
          <TextField
            className="bg-white w-full"
            id="userPhoneEdit"
            variant="outlined"
            size="small"
            sx={{ width: 400 }}
            defaultValue={phone || user?.phone}
            onChange={(e) => setPhoneCheck(e.target.value)}
          />
        </div>
        <Divider className="mt-4" />

        <div className="lg:flex gap-2 py-4">
          <p className="w-[25%] text-gray-900 text-sm font-medium">About Me</p>
          <div className="w-full">
            <RichTextEditor
              id="itemAboutMe"
              name="itemAboutMe"
              onChange={setTextEditorAbout}
              defaultValue={aboutMe}
              className="max-h-[200px]"
            />
          </div>
        </div>
        <Divider />

        <div className="lg:flex gap-2 py-4">
          <p className="w-[25%] text-gray-900 text-sm font-medium">Likes</p>
          <div className="w-full">
            <RichTextEditor
              id="itemLikes"
              name="itemLikes"
              onChange={setTextEditorLikes}
              defaultValue={likes}
              className="max-h-[200px]"
            />
          </div>
        </div>
        <Divider />

        <div className="lg:flex gap-2 py-4">
          <p className="w-[25%] text-gray-900 text-sm font-medium">Dislikes</p>
          <div className="w-full">
            <RichTextEditor
              id="itemDislikes"
              name="itemDislikes"
              onChange={setTextEditorDislikes}
              defaultValue={dislikes}
              className="max-h-[200px]"
            />
          </div>
        </div>
        <Divider />

        <div className="lg:flex gap-2 py-4">
          <p className="w-[25%] text-gray-900 text-sm font-medium">Hobbies</p>
          <div className="w-full">
            <RichTextEditor
              id="itemHobbies"
              name="itemHobbies"
              onChange={setTextEditorHobbies}
              defaultValue={hobbies}
              className="max-h-[200px]"
              // debounce={0}
            />
          </div>
        </div>
      </DialogContent>
    </form>
  )
}

export default EditUserProfileForm
