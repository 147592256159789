import { useEffect, useState } from 'react'

import { XMarkIcon } from '@heroicons/react/24/outline'
import Drawer from '@mui/material/Drawer'
import { FLAG_FEATURES } from 'api/src/common/enums'
import { useBoolean, useMediaQuery } from 'usehooks-ts'

import { useParams } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import InformerDirectoryListCell from 'src/components/InformerDirectoryListCell'
import InformerDirectoryUserCell, {
  EmptyInformerDirectoryUser,
} from 'src/components/InformerDirectoryUserCell'
import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import IconButton from 'src/components/Library/IconButton'
import PageHeader from 'src/components/PageHeader/PageHeader'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'
import useAnalytics from 'src/lib/hooks/useAnalytics'
import { useAuth } from 'web/src/Providers'

const InformerDirectoryPage = ({ membershipId }) => {
  const { currentUser } = useAuth()
  const [selectedMembership, setSelectedMembership] = useState<number>(
    membershipId ? parseInt(membershipId) : 0,
  )
  const paramData = useParams()
  const isMobile = useMediaQuery('(max-width: 769px)')
  const menuOpen = useBoolean(false)
  const { trackEvent } = useAnalytics()

  useEffect(() => {
    if (paramData.membershipId) {
      setSelectedMembership(parseInt(paramData.membershipId))
    }
  }, [paramData])
  useEffect(() => {
    if (!selectedMembership) return
    if (isMobile) {
      menuOpen.setTrue()
    }
    trackEvent('People', 'Viewed Profile', {
      viewedMembership: selectedMembership,
    })
  }, [selectedMembership])

  return (
    <>
      <Metadata title="Directory" description="User Directory" />
      <PageHeader title="Directory" parentDataTestId="directory-page" />
      <FeatureToggle
        feature={FLAG_FEATURES.INFORMER_DIRECTORY}
        InactiveComponent={<FeatureDenied />}
      >
        <div className="relative z-0 flex flex-1 overflow-y-scroll lg:h-[calc(100vh-64px)]">
          {isMobile && (
            <Drawer
              anchor="bottom"
              open={menuOpen.value}
              onClose={() => {
                menuOpen.setFalse()
              }}
              sx={{
                flexShrink: 0,
                zIndex: 50,
                '& .MuiDrawer-paper': {
                  height: '100%',
                },
              }}
            >
              <div className="static">
                <div className="absolute z-10 flex w-full justify-end">
                  <IconButton
                    aria-label="close"
                    onClick={menuOpen.setFalse}
                    className="m-2 bg-white opacity-80 shadow-xl"
                    size="small"
                  >
                    <XMarkIcon className="h-5 w-5" />
                  </IconButton>
                </div>
                {selectedMembership ? (
                  <InformerDirectoryUserCell
                    searchId={selectedMembership}
                    updateSelectedMembership={setSelectedMembership}
                  />
                ) : (
                  <EmptyInformerDirectoryUser />
                )}
              </div>
            </Drawer>
          )}
          {!isMobile && (
            <main className="relative z-0 order-last flex-1 overflow-y-auto focus:outline-none">
              {selectedMembership ? (
                <InformerDirectoryUserCell
                  searchId={selectedMembership}
                  updateSelectedMembership={setSelectedMembership}
                />
              ) : (
                <EmptyInformerDirectoryUser />
              )}
            </main>
          )}
          <aside
            className={`flex flex-shrink-0 flex-col ${
              isMobile ? 'w-full' : 'w-80'
            } border-r border-gray-200 ${isMobile && 'mb-[45px]'}`}
          >
            <InformerDirectoryListCell
              searchId={currentUser.parentData.id}
              searchType={currentUser.membershipData.membershipType}
              updateSelectedMembership={setSelectedMembership}
            />
          </aside>
        </div>
      </FeatureToggle>
    </>
  )
}

export default InformerDirectoryPage
