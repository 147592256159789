// TODO: add support for direct links to user pages

import { FC, useEffect, useState } from 'react'

import { directoryListClientQuery } from 'types/graphql'

import { AzDirectory } from '../InformerDirectoryListCell'
import { SearchField } from '../Library/SearchField/SearchField'
import UserAvatar from '../UserAvatar/UserAvatar'

type Props = {
  directoryList: directoryListClientQuery['directoryList']
  azDirectoryList: AzDirectory
  updateSelectedMembership: (id: number) => void
}

type UserData = AzDirectory[keyof AzDirectory][0]

const InformerDirectoryList: FC<Props> = ({
  azDirectoryList,
  updateSelectedMembership,
}) => {
  const dataListRaw = azDirectoryList
  const [searchVal, setSearchVal] = useState('')
  const [dataList, setDataList] = useState(dataListRaw)

  useEffect(() => {
    if (searchVal === '' || searchVal === null) {
      setDataList(dataListRaw)
    } else {
      const newDataList: AzDirectory = {}
      const searchLetters = Object.keys(dataListRaw)

      for (const letter of searchLetters) {
        let letterValid = false
        const letterArray: UserData[] = []

        for (const userData of dataListRaw[letter]) {
          const user = userData.user
          const hasEmailMatch = () =>
            user.email?.toLowerCase().includes(searchVal.toLowerCase())
          const hasPositionMatch = () =>
            user.position?.toLowerCase().includes(searchVal.toLowerCase())
          const hasNameMatch = () =>
            user.name?.toLowerCase().includes(searchVal.toLowerCase())

          if (hasEmailMatch() || hasPositionMatch() || hasNameMatch()) {
            letterValid = true
            letterArray.push(userData)
          }
        }

        if (letterValid) {
          newDataList[letter] = letterArray
        }
      }

      setDataList(newDataList)
    }
  }, [searchVal])

  return (
    <>
      <div className="py-3 px-2 max-h-full" data-testid="search-users-wrapper">
        <SearchField
          placeholder="Search Users"
          value={searchVal}
          onChange={setSearchVal}
          className="w-full"
        />
      </div>
      {/* Directory list */}
      <nav
        className="flex-1 min-h-0 overflow-y-scroll max-h-full"
        aria-label="Directory"
      >
        {Object.keys(dataList).map((letter) => (
          <div key={letter}>
            <div className="z-10 sticky top-0 border-t border-b border-gray-200 bg-gray-100 px-6 py-1 text-sm font-medium text-gray-500">
              <h3>{letter}</h3>
            </div>
            <ul className="relative z-0 divide-y divide-gray-200 bg-white">
              {dataList[letter].map((person) => (
                <li key={person.id}>
                  <div className="relative px-6 py-3 flex items-center space-x-3 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 hover:bg-gray-100">
                    <div className="flex-shrink-0">
                      <UserAvatar src={person.user.avatarUrl} height={8} />
                    </div>
                    <div className="flex-1 min-w-0">
                      <div
                        className="focus:outline-none"
                        onClick={(e) => {
                          // @ts-expect-error untyped
                          const personId = parseInt(e.target?.dataset.personid)
                          updateSelectedMembership(personId)
                        }}
                        onKeyUp={() => {}}
                        tabIndex={0}
                        role="button"
                        data-personid={person.id}
                        data-testid={`directory-list-item-${person.id}`}
                      >
                        {/* Extend touch target to entire panel */}
                        <span
                          className="absolute inset-0"
                          aria-hidden="true"
                          data-personid={person.id}
                        />
                        <p className="text-xs font-medium text-gray-900">
                          {person.user.name}
                        </p>
                        <p className="text-xs text-gray-500 truncate">
                          {person.user.position}
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        ))}
      </nav>
    </>
  )
}

export default InformerDirectoryList
