import { FC, useState, useEffect } from 'react'

import { Box } from '@mui/material'
import { GoalStatusTypes, NewGoalStatusTypes } from 'api/src/common/enums'
import { ImproverCardsQuery } from 'types/graphql'

import { Link, routes } from '@redwoodjs/router'

import Milestone from 'src/components/Goals/MainPanel/GoalList/GoalItem/Milestone/Milestone'
import Empty from 'src/components/Library/Empty/Empty'
import { useAuth } from 'src/Providers'

import FilterNavTabs from '../../../FilterNavTabs/FilterNavTabs'

import GoalObjective from './GoalItem/GoalObjective/GoalObjective'
interface PersonalGoalListProps {
  goals: ImproverCardsQuery['goals']
  statusCount: {
    all: number
    completed: number
    inProgress: number
    blocked: number
    overdue: number
  }
  membershipId: number
}
const PersonalGoalList: FC<PersonalGoalListProps> = ({
  goals,
  statusCount,
  membershipId,
}) => {
  const { currentUser } = useAuth()

  const [selectedObjective, setSelectedObjective] = useState<number>(null)

  const currentUserMembershipId = currentUser.membershipData.id

  // The goals to display, based on the active tab
  const [goalsToDisplay, setGoalsToDisplay] =
    useState<ImproverCardsQuery['goals']>(goals)

  // Handle tab change
  const handleStatusFilterChange = (status: string) => {
    if (status === 'All') {
      // Send all the goals
      setGoalsToDisplay(goals)
    } else {
      // Filter out the goals that don't match the status
      const filteredGoals = goals.filter(
        (goal) => goal?.currentStatus === status,
      )

      // Send them to display
      setGoalsToDisplay(filteredGoals)
    }
  }

  // Set Nav Tab Filter counts
  const navTabItems: {
    key?: GoalStatusTypes
    title: NewGoalStatusTypes | 'All'
    count: number
  }[] = [
    {
      key: GoalStatusTypes.inProgress,
      title: NewGoalStatusTypes.inProgress,
      count: statusCount.inProgress,
    },
    {
      key: GoalStatusTypes.blocked,
      title: NewGoalStatusTypes.blocked,
      count: statusCount.blocked,
    },
    {
      key: GoalStatusTypes.overdue,
      title: NewGoalStatusTypes.overdue,
      count: statusCount.overdue,
    },
    {
      key: GoalStatusTypes.completed,
      title: NewGoalStatusTypes.completed,
      count: statusCount.completed,
    },

    { title: 'All', count: statusCount.all },
  ]

  // Refresh content on change. Helps update content when switching selected user
  useEffect(() => {
    const onTrackGoals = goals.filter(
      (goal) => goal?.currentStatus === GoalStatusTypes.inProgress,
    )

    setGoalsToDisplay(onTrackGoals)
  }, [goals])

  return (
    <div className="w-full max-w-[1200px] mx-auto px-6">
      <Box>
        <Box className="border-b">
          <FilterNavTabs
            onTabClickCallBack={handleStatusFilterChange}
            navTabs={navTabItems}
            selectedTab={0}
            GATracker={{
              category: 'Goals',
              eventName: 'change tab profile goals',
            }}
          />
        </Box>

        <div className="py-5 flex flex-col gap-4 pb-10">
          {!goalsToDisplay.length && (
            <div className="text-center pt-8">
              {currentUserMembershipId === membershipId && (
                <>
                  <h3 className="my-2 text-sm font-medium text-gray-900">
                    You do not have any on track goals.
                  </h3>
                  <p className="mt-1 text-sm text-indigo-500 underline hover:text-indigo-400">
                    <Link to={routes.improverGoals()}>
                      Click here to create a new goal.
                    </Link>
                  </p>
                </>
              )}
              {currentUserMembershipId !== membershipId && (
                <>
                  <Empty title="No Goals Found." />
                </>
              )}
            </div>
          )}

          {goalsToDisplay.length > 0 &&
            goalsToDisplay.map((goal) => (
              <GoalObjective
                goal={goal}
                key={goal.id}
                allowOpen={Boolean(
                  goal?.goalBody || goal?.childGoals.length > 0,
                )}
                setSelectedObjective={setSelectedObjective}
                selectedObjective={selectedObjective}
                isProfileView={true}
              >
                <div className="flex flex-col gap-6 pl-6 border-l border-gray-200 mb-6">
                  {goal?.childGoals.map((milestone) => (
                    <Milestone key={milestone.id} milestone={milestone} />
                  ))}
                </div>
              </GoalObjective>
            ))}
        </div>
      </Box>
    </div>
  )
}

export default PersonalGoalList
