import { FC } from 'react'

import { ImproverCardsQuery } from 'types/graphql'

import { CellSuccessProps } from '@redwoodjs/web'

import { countGoals } from 'src/components/Goals/helpers'
import { default as LoadingSpinner } from 'src/components/Library/Loading'

import PersonalGoalList from '../PersonalGoalList'

export const QUERY = gql`
  query ImproverCardsQuery($membershipId: Int!) {
    goals: improverGoalMembersByMembership(membershipId: $membershipId) {
      ...ImproverGoalMemberFields
      aclPrincipals {
        ...ACLFields
        principal {
          __typename
          ... on ACLPrincipalMembership {
            membership {
              id
              user {
                id
                name
                avatarUrl
              }
            }
          }
          ... on ACLPrincipalMembershipGroup {
            membershipGroup {
              id
              name
            }
          }
        }
      }
      childGoals {
        ...ImproverGoalMemberFields
        aclPrincipals {
          ...ACLFields
          principal {
            __typename
            ... on ACLPrincipalMembership {
              membership {
                id
                user {
                  id
                  name
                  avatarUrl
                }
              }
            }
            ... on ACLPrincipalMembershipGroup {
              membershipGroup {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const Loading = () => <LoadingSpinner />

export const Empty = ({ membershipId }) => {
  return (
    <PersonalGoalList
      goals={[]}
      statusCount={countGoals([])}
      membershipId={membershipId}
    />
  )
}

export const Failure = ({ error }) => (
  <div style={{ color: 'red' }}>Error: {error.message}</div>
)

export const Success: FC<
  CellSuccessProps<ImproverCardsQuery & { membershipId: number }>
> = ({ goals, membershipId }) => {
  // Get parent level goals
  const allObjectives = goals.filter((goal) => goal.parentGoalId === null)

  const objectiveStatusCounts = countGoals(allObjectives)

  return (
    <PersonalGoalList
      goals={allObjectives}
      statusCount={objectiveStatusCounts}
      membershipId={membershipId}
    />
  )
}
